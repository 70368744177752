/* Autor: Alexis Yael Hernández Grimaldo */

body {
    background: #eee;
    padding-bottom: 56px !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

html {
    min-height: 100% !important;
    position: relative !important;
}

input[type="checkbox"] {
    display: none;
}

.custom-file-label{
    border: none;
    border-bottom: 3px solid #ced4da;
    border-radius: 0px;
    height: calc(1.5em + .75rem + 6px);
}
.custom-file-label:focus{
    border:none;
    box-shadow:none;
}
.custom-file-input:focus{
    border:none;
    box-shadow:none;
}
.check-circle {
    position: relative;
}

.check-circle::before {
    content: "";
    background: url("../img/check-circle.svg");
    background-position: center;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    left: -4px;
    top: -4px;
    transform: scale(0) rotateZ(180deg);
    transition: all 0.4s cubic-bezier(0, 0, 0, 1.49);
}

input[type="checkbox"]:checked + .check-circle::before {
    transform: scale(1) rotateZ(0deg);
}

.check-circle-disabled::after {
    content: "";
    border: 2px solid #72c997;
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
}
.check-circle::after {
    content: "";
    border: 2px solid #27ae60;
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
}
.switch {
    display: inline-block;
    height: 26px;
    position: relative;
    width: 52px;
  }

  .switch input {
    display:none;
  }

  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
  }

  .slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 22px;
    left: 2px;
    position: absolute;
    transition: .4s;
    width: 22px;
  }

  input:checked + .slider {
    background-color: #27ae60;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

:root {
    /* Colores para el header del sistema */
    --header-backgroud: #333;
    --header-color: #fff;
    --header-button-background: #555;
    /* Colores para el header de todas las tablas */
    --header-table-backgroud: #981914;
    --header-table-color: #fff;
    /* Colores para el body de todas las tablas */
    --body-table-background: #fff;
    --body-table-color: #000;
    /* Colores para el footer del sismema */
    --footer-backgroud: #333;
    --footer-color: #fff;
    --footer-border: solid 3px #981914;
    /* Colores para lapso de tiempo */
    --border-green-table: solid 3px #3fca469f;
    --border-red-table: solid 3px #ff3131a8;
    --border-yellow-table: solid 3px #ffcc40b6;
    /* Colores para el login */
    --header-login-backgroud: #981914;
    --header-login-color: #fff;
    --body-login-color: #fff;
    --body-login-background: #333;
    --button-login-background: #fff;
    --button-login-color: #000;
    /* Colores generales */
    --green: #3fca469f;
    --red: #ff3131a8;
    --yellow: #ffcc40b6;
    --black: #000;
    --white: #fff;
    --grey: #cbcbcb;
}

/* Clase para color de botones dentro del header */
.button-header{
    border-radius: 100%;
    padding:5px 8px;
    background: var(--header-button-background);
    font-size: 16px;
    position: relative;
}

.button-header .m-pendiente {
    position:absolute;
    background: #dc3545;
    border-radius:100%;
    width:11px;
    height: 11px;
    top:-1px;
    right: -1px;
}
#drop-down-buttons{
    position: absolute;
    z-index: 3;
    background: #fff;           
    padding: 14px 5px; 
    border-radius: 5px; 
    border: solid 1px rgba(0,0,0,.15)
}

/* Clase para swal */

.swal-text {
    text-align: center;
    font-size: 16px;
}


/* Clase para el header del sistema */

.header {
    background: var(--header-backgroud);
    color: var(--header-color);
}


/* Clase para el footer del sistema */

.footer {
    position: absolute !important;
    bottom: 0 !important;
    background: var(--footer-backgroud);
    color: var(--footer-color);
    border-top: var(--footer-border);
}


/* Clase para para el header de las tablas del sistema */

.header-table {
    background: var(--header-table-backgroud);
    color: var(--header-table-color);
    font-size: 17px;
}


/* Clase para el body de las tablas del sistema */

.body-table {
    background: var(--body-table-background);
    color: var(--body-table-color);
    font-size: 17px;
}

/* Clase para centrar elementos de celda */

.custom-row td {
    display: table-cell;
    vertical-align: middle;
}


/* Clase de color de los badge de Reacstrap (text) */

.badge-TextColor {
    color: var(--white);
}

/* Clase para el tr de color rojo de lapso de tiempo de la tabla */

.border-red {
    border-bottom: var(--border-red-table);
    transition: 0.3s;
}


/* Hover de la tr roja del lapso de tiempo */

.border-red:hover {
    background: var(--red) !important;
}


/* Clase para el tr de color amarillo de lapso de tiempo de la tabla */

.border-yellow {
    border-bottom: var(--border-yellow-table);
    transition: 0.3s;
}


/* Hover de la tr amarilla del lapso de tiempo */

.border-yellow:hover {
    background: var(--yellow) !important;
}


/* Clase para el tr de color verde de lapso de tiempo de la tabla */

.border-green {
    border-bottom: var(--border-green-table);
    transition: 0.3s;
}


/* Hover de la tr verde del lapso de tiempo */

.border-green:hover {
    background: var(--green) !important;
}


/* Centrado del login */

.abs-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 80vh;
}


/* Tamaño de las tablas del sistema */

.tm-table {
    overflow-x: scroll;
    overflow-y: scroll;
    min-height: 500px;
    max-height: 600px;
}

/* Propiedad de los swal para el centrado del texto */

.swal-text {
    text-align: center;
}


/* Clase de header para el login del ssistema */

.header-login {
    background: var(--header-login-backgroud);
    color: var(--header-login-color) !important;
}

.body-login {
    background: var(--body-login-background);
    color: var(--body-login-color)
}

.button-login {
    background: var(--button-login-background) !important;
    color: var(--button-login-color);
}

.background-red {
    background: var(--red);
}

.background-yellow {
    background: var(--yellow);
}

.background-green {
    background: var(--green);
}

.background-black {
    background: var(--black);
    color: var(--white);
}

.background-grey{
    background: var(--grey);
}
.buttons {
    color: #303030;
}

.buttons:hover {
    color: white;
}
.status-table{
    background:var(--red);
    border-radius: 10px;
    padding: 0px 10px;
}
/* ---------------------------------- APARTADO DE INPUT ANIMADO ---------------------------------- */
.grupo{
    position: relative;
    padding: 0px 5px !important;}
.input-animated{
    padding: 13px 10px 0px 10px !important;
    border:none;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 3px solid #ced4da;
    height:50px;  
    font-size:16px;
    outline: none;}
.select-animated {
    padding: 13px 22px 0px 5px; 
    height: 50px; 
    border: none; 
    border-radius: 5px 5px 0px 0px;
    border-bottom: 3px solid #ced4da;
    font-size: 16px}
.label-animated{
    position: absolute;
    top: 11px;
    left: 13px;
    transition: 0.4s ease-in-out all;
    pointer-events: none;
    padding: 1px 2px;
    color: #6c757d;}
.label-animated-select {
    top: 0px;
    font-size: 12px;
    color: #6c757d;
    -moz-outline-radius: 5px;}
.label-select-custom{
    position: absolute;
    top: 11px;
    left: 13px;
    pointer-events: none;
    padding: 1px 2px;
    color: #6c757d;
    top: 0px;
    font-size: 12px;
    color: #6c757d;
}
.label-select-custom-focus{
    color: #007bff;
}
.input-animated:disabled{
    background: #e9ecef;
    color: #495057;
    border-radius: 5px 5px 0px 0px;}
.select-animated:disabled{
    background: #e9ecef;
    color: #495057;
    border-radius: 5px 5px 0px 0px;}
.input-animated:focus{
    background: rgba(0,0,0,.08);
    border-radius: 5px 5px 0px 0px;
    border-bottom: 3px solid #007bff;}
.select-animated:focus{
    background: rgba(0,0,0,.08);
    border-radius: 5px 5px 0px 0px;
    border-bottom: 3px solid #007bff;
    box-shadow: none;}
.input-animated:focus~.label-animated,
.input-animated:not(:placeholder-shown)~.label-animated{
    top: 0px;
    font-size: 12px;
    color: #6c757d;
    -moz-outline-radius: 5px;}
.select-animated~.label-animated{
    top: 0px;
    font-size: 12px;
    color: #6c757d;
    -moz-outline-radius: 5px;}
.input-animated:focus~.label-animated{
    color: #007bff;}
.select-animated:focus~.label-animated{
    color: #007bff;}
.imagen-usuario-chat {
    display: inline-block;
    border-radius: 100%; 
    font-size: 24px;
    font-weight: bolder;
    width: 55px !important;
    height: 55px !important;                                   
    text-align: center;
}
/* ---------------------------------- FIN APARTADO DE INPUT ANIMADO ---------------------------------- */
.dropzone-container {
    border: #ced4da solid 2px;
    border-style: dashed;
    height:100px;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: move; 
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.tamaño-notificacion {
    height: 580px; 
    overflow-y: scroll;
}
.tamaño-chat{
    overflow-y: auto; 
    max-height: 600px; 
    height: 600px;
    border-left: solid 1px #AAA;
    border-right: solid 1px #AAA;
    background: #fff;
}
/* MEDIA PARA LAPTOPS DE 1440 */
@media screen and (max-width: 1440px){
    .tamaño-chat{
        height:550px;
    }
}

@media screen and (max-width: 1300px) {
    .tamaño-notificacion{
        height: 450px;
    }
    .tamaño-chat{
        height: 500px;
    }
}

@media screen and (max-width: 400px){
    .tamaño-chat{
        height:380px;
    }
}


.select {
    padding-top: 5px;
    border:none;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 3px solid #ced4da;
    height:50px;  
    font-size:16px;
    outline: none;
}
.select_backgroud{
    background: rgba(0,0,0,.08) !important;
    border-bottom: 3px solid #007bff;
}
.select._active:after {
    transform: rotate(-135deg);
}
.select:after {
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    position: absolute;
    right: 1.2em;
    top: 1.4em;
    transform: rotate(45deg);
}
.select-option {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5em;
    overflow: hidden;
    background:white;
    border-bottom: 1px solid #ced4da;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select-option:hover {
    background: #eee;
}
.select-option:last-child {
    border-radius: 0 0 0.2em 0.2em;
    border-bottom: none;
}
.select-selected {
    width: 95%;
    box-sizing: border-box;
    padding: 0.5em;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hover-gray:hover{
    color: #6c757d;
}

.text-space{
    margin-right: 40px;
    margin-left: 40px;
}

.text-space-importe{
    margin-right: 4px;
    margin-left: 4px;
}

.celda-anticipos{
    position: relative;
}

.saldo-anticipos{
    position: absolute;
    width: 100%;
    bottom: 0;
}

.btn-regresar-anticipos{
    position: relative;
    bottom: -2rem;
}

.general_sub{
    font-size: 1.5rem;
}

.pointer-cursor{
    cursor: pointer;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.rounded-box {
    border: 1px solid white;
    background-color: #bababa;
    border-radius: 10px;
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
}